.hero {
  display: flex;
  justify-content: space-between;
}

.left {
  padding: 2rem;
  flex: 3;
  padding-top: 1.5rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.right {
  flex: 1;
  position: relative;
  background-color: var(--orange);
}

.thebest {
  margin-top: 4rem;
  background-color: #363d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 2rem 1.3rem;
  text-transform: uppercase;
  color: wheat;
  position: relative;
  display: flex;
  flex-direction: flex-start;
  justify-content: center;
}

.thebest > span {
  z-index: 2;
}

.thebest > div {
  position: absolute;
  background-color: var(--orange);
  width: 5.4rem;
  height: 80%;
  left: 0.8rem;
  border-radius: 3rem;
  top: 5px;
}

.main-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 4.5rem;
  color: aliceblue;
  text-overflow: inherit;
}

.main-text > div:nth-of-type(3) {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.figures {
  display: flex;
  gap: 2rem;
}

.figures > div {
  display: flex;
  flex-direction: column;
}

.figures > div > span:nth-of-type(1) {
  color: azure;
  font-size: 2rem;
}

.figures > div > span:nth-of-type(2) {
  color: var(--gray);

  text-transform: uppercase;
}

.button {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: #363d42;
  width: 10rem;
  height: 4rem;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  background-color: rgb(250, 229, 191);
  box-shadow: 2px 2px 2px 4px peru, -2px -2px 2px 4px orangered;
}

.button:hover {
  background-color: var(--appColor);
  color: antiquewhite;
}

.pulse {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--gray);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 4rem;
  top: 7rem;
}

.pulse > img {
  width: 2rem;
}

.pulse > span:nth-child(2) {
  color: var(--orange);
}

.pulse > :nth-child(3) {
  color: white;
}

.imgmain {
  position: absolute;
  right: 8rem;
  top: 10rem;
  width: 23rem;
}

.backimg {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 15rem;
}

.calories {
  display: flex;
  gap: 2rem;
  background-color: var(--caloryCard);
  border-radius: 5px;
  width: fit-content;
  position: absolute;
  top: 36rem;
  right: 32rem;
  padding: 1rem;
}

.calories > img {
  width: 3rem;
}

.calories > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--gray);
}

.calories > div > span:nth-child(2) {
  color: white;
}

.calories > div > span:nth-child(1) {
  color: white;
  font-size: 1rem;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
  }
  .blur-h {
    width: 14rem;
  }
  .thebest {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .main-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero > div:nth-child(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero > div:nth-child(2) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .button {
    justify-content: center;
  }
  .hero > div:nth-child(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .figures>div{
    align-items: center;
    margin-left: -10px;
  }
  .figures > div > span:nth-child(1) {
    font-size: large;
  }
  .figures > div > span:nth-child(2) {
    font-size: small;
  }

  .btn,.btn2{
    margin-left: -10px;

  }
  .button{
    width: 100px;
    align-items:center ;
  }
  .right {
    position: relative;
    background: none;
  }
  .pulse {
    left: 1rem;
    top: 2rem;
  }
  .pulse{margin: -10px  0 0 0;}
  .imgmain{
    margin-left: -10px;margin-top: 10rem;
  }
  .calories {
    position: relative;
    top: 5rem;
    left: 2rem;
  }
  .calories > img {
    width: 2rem;
  }
  .calories > div > :nth-child(2) {
    color: white;
    font-size: 1rem;
  }
  .imgmain {
    position: relative;
    width: 15rem;
    left: 4rem;
    top: 4rem;
    align-items: center;
  }
  
}
