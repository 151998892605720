.program {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
}

.programheader {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

.programheader > span:nth-child(1),
.programheader > span:nth-child(3) {
  font-size: 3.2rem;
}

.program-categories {
  display: flex;
  gap: 1rem;
}

.category {
  display: flex;
  flex-direction: column;
  background-color: gray;
  padding: 2rem;

  margin: 0.5rem;
  gap: 1rem;
  color: white;
  justify-content: space-between;
}

.category > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}

.category > :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}

.category > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}

.join-now {
  display: flex;
  align-items: center;
  grid: 2rem;
}

.join-now > img {
  width: 1rem;
}
.category:hover {
  background: var(--planCard);
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .programheader>span{
    font-size: 15px !important;
  }
 
  .programheader>.storkeb{
    margin-left:20px ;
  }
  .program-categories{
    display: flex;
    gap: 1rem;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
}