.join {
  display: flex;
  gap: 10rem;
  padding: 0 2rem;
}

.join-l > div span {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.join-l > div > hr {
  position: absolute !important;
  width: 10.5rem !important;
  border: 2px solid var(--orange);
  border-radius: 20%;
  margin: -10px 0;
}

.join-r {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.email {
  display: flex;
  gap: 3rem;

  background-color: gray;
  padding: 1rem;
}
.email > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgrayz);
}

::placeholder {
  color: var(--lightgrayz);
}

@media screen and (max-width: 768px) {
  .join {
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
    flex-wrap: wrap;
    justify-content: center;
  }
}
