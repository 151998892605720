.footer {
  display: inline-block;
  text-align: center;
  align-items: center;
}
.footer > span {
  align-items: center;
  font-size: 3.5rem;
}

@media screen and (max-width: 786px) {
  .footer > span {
    font-size: 2rem !important;
  }
}
