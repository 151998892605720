.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 10rem;
  height: 3rem;
}

.header-menu {
  display: flex;
  list-style: none;
  gap: 2rem;
  color: #fff;
}

.header-menu > li:hover {
  color: var(--orange);
  cursor: pointer;
}

.blur-h {
  width: 22rem;
  height: 39rem;
  left: 0;
}

@media screen and (max-width: 768px) {
  .header{
    position: fixed;
    right: 2rem;
    z-index: 999;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--orange);
     padding: 2rem;
  }
}
