.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.plan {
  display: flex;
  flex-direction: column;
  background-color: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.25rem;
  width: 15rem;
}

.plan:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}

.plan > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}

.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: 2rem;
}

.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}

.plan > :nth-child(5) {
  font-size: 0.8rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fetures > img {
  width: 1rem;
}

.plans > :nth-child(2) > svg {
  fill: white;
}

/* button */

.pi-button,
.raise-button {
  letter-spacing: 1px;
  font-size: 12px;
  display: inline-block;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  font-family: sans-serif;
  cursor: pointer;
  border: none;
  padding: 12px 20px 12px 20px;
  margin: 5px;
  background-color: transparent;
  text-align: center;
  color: #444;
}

.pi-button:focus,
.raise-button:focus {
  outline: none;
}

.pi-button:hover {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22), 0 4px 4px 0 rgba(0, 0, 0, 0.35);

  color: #222;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.6);
}

.pi-button:active {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.44), 0 1px 1px 0 rgba(0, 0, 0, 0.68);
}

.pi-button:focus::before {
  display: block;
  height: 150px;
  width: 150px;
  top: -58px;
  left: -30px;
  opacity: 0;
  content: "";
  background: #aaa;
  border-radius: 1000px;
  position: absolute;
  animation-name: fade;
  animation-timing-function: ease-out;
  animation-duration: 1s;
  z-index: -2;
}

@keyframes fade {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
  }
}

.with-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 1px 0 rgba(0, 0, 0, 0.59);
  background-color: rgba(255, 255, 255, 0.3);
}

.raise-button:hover {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3), 0 15px 15px 0 rgba(0, 0, 0, 0.4);
  background-color: #fff !important;
  color: #222 !important;
}

.raise-button:active {
  -webkit-transition: 0.05s ease-out;
  transition: 0.05s ease-out;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.44), 0 1px 1px 0 rgba(0, 0, 0, 0.68);
}

.plan-1 {
  width: 32rem;
  height: 23rem;
  top: 0;
  left: 0;
}

.plan-2 {
  width: 32rem;
  height: 23rem;
  top: 10;
  left: 0;
}



@media screen and (max-width: 768px) {.plans{
  display: flex;
  gap: 1rem;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 2rem 0;
}}